import { Backend } from '../backend.js';

const filterItemsByTags = (selectedTags, items) => {
    // Ensure selectedTags is an array and normalize to lowercase
    const selectedTagsArray = Array.isArray(selectedTags) ? selectedTags : [selectedTags];
    const normalizedTags = selectedTagsArray.map(tag => tag.toLowerCase());

    console.log('Normalized Selected Tags:', normalizedTags); // Debug statement

    return items.filter(item => {
        // Normalize item tags to lowercase
        const itemTags = (item.tags || []).map(tag => tag.toLowerCase());

        console.log('Item Tags:', itemTags); // Debug statement

        // Check if every tag in selectedTagsArray is included in itemTags
        return normalizedTags.every(tag => itemTags.includes(tag));
    });
};

const searchItemsByWords = (searchTerm, items) => {
    if (!searchTerm || searchTerm.trim() === '') {
        // If searchTerm is empty, return the items as is
        return items;
    }

    const searchWords = searchTerm.toLowerCase().split(/\s+/);

    console.log('Search Words:', searchWords); // Debug statement

    return items.filter(item => {
        // Combine all relevant fields into a single string for searching
        const itemFields = [
            item.title,
            item.category.map(c => c.name).join(' '),
            item.category.flatMap(c => c.subCategory.map(sc => sc.name)).join(' '),
            item.info.description,
            item.info.purpose,
            item.info.characteristics,
            item.info.technicalCharacteristics,
            item.info.advantages,
            item.info.recommendationsForUse,
            item.info.safety
        ].filter(Boolean).join(' ').toLowerCase();

        console.log('Item Fields:', itemFields); // Debug statement

        // Check if any search word is a substring of the item fields
        return searchWords.some(word => itemFields.includes(word));
    });
};

export const searchEngine = async (selectedTags, searchTerm) => {
    try {
        const backend = new Backend();
        const items = await backend.getItems();

        // First filter items by tags
        const tagFilteredItems = filterItemsByTags(selectedTags, items);

        // Then search within the tag-filtered items if a search term is provided
        const result = searchItemsByWords(searchTerm, tagFilteredItems);
        console.log('Search Results:', result); // Debug statement

        return result;
    } catch (error) {
        console.error('Error during search:', error.message);
        return [];
    }
};
