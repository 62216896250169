import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Backend } from '../backend.js';
import { searchEngine } from './searchEngine';  // Adjust the import path as needed

function SearchPageLeft({ setDisplayItems, setError }) {
    const [searchTags, setSearchTags] = useState({ typesOfUsage: [], typeOfProduct: [] });
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchError, setSearchError] = useState(null);

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const backend = new Backend();
                const tags = await backend.getTags();
                setSearchTags(tags);
            } catch (error) {
                setSearchError('Failed to fetch tags. Please try again later.');
                console.error('Failed to fetch tags:', error);
            }
        };

        fetchTags();
    }, []);

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setSelectedTags(prev =>
            checked ? [...prev, name] : prev.filter(item => item !== name)
        );
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        try {
            const searchResult = await searchEngine(selectedTags, searchTerm);
            setDisplayItems(searchResult);  // Update displayItems
            setError(null);
        } catch (error) {
            setSearchError('Search failed. Please try again later.');
            console.error('Search error:', error);
        }
    };

    const handleReset = () => {
        setSelectedTags([]);
        setSearchTerm('');
        setSearchError(null);
    };

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch(e);
        }
    };

    return (
        <div className="sm:fixed relative w-full md:w-2/6 md:order-2 h-full p-8 flex flex-col justify-between border-r-2 border-gray-300">
            <form onSubmit={handleSearch} className="h-full flex flex-col">
                <label className="input input-bordered flex items-center gap-2 mb-4 w-full">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyPress}
                        className="grow h-10 border-0 p-2"
                        placeholder="Поиск"
                    />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        className="h-4 w-4 opacity-70"
                        aria-label="Search Icon"
                    >
                        <path
                            fillRule="evenodd"
                            d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                            clipRule="evenodd"
                        />
                    </svg>
                </label>

                <div className='h-1 bg-primary mb-4'></div>

                <div className="mb-4">
                    <h3 className='font-bold'>Способы использования</h3>
                    {searchTags.typesOfUsage.map((usage) => (
                        <div key={usage}>
                            <label>
                                <input
                                    type="checkbox"
                                    name={usage}
                                    checked={selectedTags.includes(usage)}
                                    onChange={handleCheckboxChange}
                                    className='mr-2'
                                />
                                {usage}
                            </label>
                        </div>
                    ))}
                </div>

                <div className="mb-4">
                    <h3 className='font-bold'>Тип моющего средства</h3>
                    {searchTags.typeOfProduct.map((product) => (
                        <div key={product}>
                            <label>
                                <input
                                    type="checkbox"
                                    name={product}
                                    checked={selectedTags.includes(product)}
                                    onChange={handleCheckboxChange}
                                    className='mr-2'
                                />
                                {product}
                            </label>
                        </div>
                    ))}
                </div>

                <div className="flex flex-col w-full mt-8">
                    <button
                        type="submit"
                        className="btn bg-primary text-white w-full"
                    >
                        Поиск
                    </button>
                    <button
                        type="button"
                        className="btn bg-base border-2 border-primary text-primary mt-2 mb-4 w-full"
                        onClick={handleReset}
                    >
                        Сброс
                    </button>
                </div>
            </form>
        </div>
    );
}

export default SearchPageLeft;
