import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom

// Components
import Header from '../Components/Header.jsx';
import Footer from '../Components/Footer.jsx';
import ItemShowcase from '../Components/ItemShowcase.jsx';
import Breadcrumbs from '../Components/Breadcrumbs.jsx';
import useScrollToTop from '../Components/useScrollToTop.js';
import Search from '../Components/Search.jsx';

// Data
import { Backend } from '../backend.js';

function Options(props) {
    useScrollToTop();

    const location = useLocation(); // Get the current location
    const [items, setItems] = useState([]);
    const { sub: topCategory, option: currentOptions } = props;

    useEffect(() => {
        const fetchItems = async () => {
            const backend = new Backend();
            const fetchedItems = await backend.getItems();
            setItems(fetchedItems);
        };

        fetchItems();
    }, []);

    const linkBase = `/categories/${topCategory.id}/${currentOptions.id}`;
    const itemsInCategory = [];

    // console.log(props)

    items.forEach((item) => {
        item.category.forEach((category) => {
            if (category.id === topCategory.id) {
                category.subCategory.forEach((subCategory) => {
                    if (subCategory === currentOptions.id) {
                        itemsInCategory.push(item);
                    }
                });
            }
        });
    });

    const title = currentOptions.name.charAt(0).toUpperCase() + currentOptions.name.slice(1);

    return (
        <div className="App flex flex-col min-h-screen w-full mt-16">
            <Header />
    
            <div className='flex-grow flex pt-4 pr-8'>
                <div className='sm:w-11/12 sm:ml-12 w-full pl-8 flex-start'>
                    <Breadcrumbs link={location.pathname} />
                    <h2 className="sm:text-5xl text-2xl font-bold mt-4 mb-5">{title}</h2>
                    <div className='flex flex-row flex-start'>
                        <div className="flex flex-wrap justify-start pb-5 pt-5 pr-8 items-center gap-4 sm:w-4/6 w-full">
                            {itemsInCategory.map((item, index) => (
                                <ItemShowcase
                                    key={index}
                                    title={item.title}
                                    link={`${linkBase}/${item.id}`}
                                    description={item.info.description}
                                    usage={item.info.recommendationsForUse}
                                    type={item.info.characteristics}
                                    purpose={item.info.purpose}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
    
            <Footer />
        </div>
    );
    
}

export default Options;
