import { getItems } from './api/getItems.js';
import { getCategories } from './api/getCategories.js';
import { getContacts } from './api/getContacts.js';
import { getTags } from './api/getTags.js';

import { createNewItem } from './api/createNewItem.js';
import { updateItem } from './api/updateItem.js';
import { setCategories } from './api/setCategories.js';
import { setContacts } from './api/setContacts.js';
import { removeItem } from './api/removeItem.js';

export class Backend {
    async getItems() {
        return await getItems();
    }

    async getCategories() {
        return await getCategories();
    }

    async getContacts() {
        return await getContacts();
    }

    async getTags() {
        return await getTags();
    }

    async createNewItem(data) {
        return await createNewItem(data);
    }

    async updateItem(data) {
        return await updateItem(data);
    }

    async setCategories(data) {
        return await setCategories(data);
    }

    async setContacts(data) {
        return await setContacts(data);
    }

    async removeItem(id) {
        return await removeItem(id);
    }
    
}
