import React, { useState, useEffect, useCallback } from 'react';
import { Backend } from '../backend'; // Ensure the path to your Backend class is correct

const Contacts = () => {
  const [location, setLocation] = useState('');
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);

  // Initialize the Backend class only once
  const backend = React.useMemo(() => new Backend(), []);

  // Fetch contacts data on component mount
  const fetchContacts = useCallback(async () => {
    try {
      const data = await backend.getContacts();
      if (data) {
        setLocation(data.location || ''); // Set location
        setContacts(data.info || []); // Set contacts info
      }
    } catch (err) {
      console.error('Failed to load data:', err);
      setError('Failed to load data');
    } finally {
      setLoading(false);
    }
  }, [backend]);

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  // Handle input changes for location
  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  // Handle input changes for a contact entry
  const handleContactChange = (index, e) => {
    const { name, value } = e.target;
    setContacts((prevContacts) => {
      const updatedContacts = [...prevContacts];
      updatedContacts[index] = { ...updatedContacts[index], [name]: value };
      return updatedContacts;
    });
  };

  // Add a new contact entry
  const handleAddContact = () => {
    setContacts((prevContacts) => [...prevContacts, { phone: '', email: '' }]);
  };

  // Remove a contact entry
  const handleRemoveContact = (index) => {
    setContacts((prevContacts) => prevContacts.filter((_, i) => i !== index));
  };

  async function handleSave() {
    const contactData = {
      location,
      info: contacts
    };

    try {
      setStatus({ type: 'info', message: 'Сохранение данных...' });

      const response = await backend.setContacts(contactData);

      if (response && response.success) {
        console.log('Data saved successfully:');
        setStatus({ type: 'success', message: 'Данные успешно сохранены.' });

        setTimeout(() => setStatus(null), 2000);
      } else {
        console.log('Failed to save data');
        setStatus({ type: 'error', message: 'Не удалось сохранить данные.' });

        setTimeout(() => setStatus(null), 2000);
      }
    } catch (error) {
      console.log('Error saving data:', error);
      setStatus({ type: 'error', message: 'Произошла ошибка при сохранении данных.' });

      setTimeout(() => setStatus(null), 2000);
    }
  }



  if (loading) return <div className="text-center text-gray-600">Loading...</div>;
  if (error) return <div className="text-center text-red-600">Error: {error}</div>;

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      {status && (
        <div className={`w-full p-4 text-white fixed top-0 left-0 transition-opacity duration-300 z-50 ${status.type === 'success' ? 'bg-green-900' : status.type === 'error' ? 'bg-red-900' : 'bg-blue-900'}`}>
          {status.message}
        </div>
      )}

      <h2 className="text-3xl font-bold mb-6 text-gray-800">Контакты</h2>

      <div className="mb-8">
        <label className="block text-lg font-medium mb-2 text-gray-700">
          <strong>Адрес/Местоположение:</strong>
        </label>
        <input
          type="text"
          value={location}
          onChange={handleLocationChange}
          className="w-full p-4 border border-gray-300 rounded-lg shadow-sm text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {contacts.map((contact, index) => (
        <div key={index} className="mb-8 p-6 border border-gray-300 rounded-lg bg-white shadow-sm">
          <h3 className="text-xl font-semibold mb-4 text-gray-800">Контакт {index + 1}</h3>
          <div className="mb-4">
            <label className="block text-lg font-medium mb-2 text-gray-700">
              <strong>Телефон:</strong>
            </label>
            <input
              type="text"
              name="phone"
              value={contact.phone}
              onChange={(e) => handleContactChange(index, e)}
              className="w-full p-4 border border-gray-300 rounded-lg shadow-sm text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="block text-lg font-medium mb-2 text-gray-700">
              <strong>Email:</strong>
            </label>
            <input
              type="email"
              name="email"
              value={contact.email}
              onChange={(e) => handleContactChange(index, e)}
              className="w-full p-4 border border-gray-300 rounded-lg shadow-sm text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <button
            onClick={() => handleRemoveContact(index)}
            className="bg-gray-800 text-white py-2 px-6 rounded-lg shadow-sm hover:bg-red-700 transition-colors"
          >
            Удалить контакт
          </button>
        </div>
      ))}

      <div className="mt-6 flex space-x-4">
        <button
          onClick={handleAddContact}
          className="bg-gray-800 text-white py-3 px-6 rounded-lg shadow-md 0 transition-colors"
        >
          Добавить контакт
        </button>
        <button
          onClick={handleSave}
          className="bg-gray-800 text-white py-3 px-6 rounded-lg shadow-md  transition-colors"
        >
          Сохранить изменения
        </button>
      </div>
    </div>
  );
};

export default Contacts;
