const formatData = (categories) => {
    return categories.map(category => ({
        id: category.id,
        name: category.name?.toLowerCase() || "unknown-category",
        subCategories: category.subCategories.map(subCategory => ({
            id: subCategory.id,
            name: subCategory.name?.toLowerCase() || "unknown-subcategory",
            items: []
        }))
    }));
};

function fun(item, structuredData, categoryIdOfItem, subCategoryId) {
    for (let i = 0; i < structuredData.length; i++) {

        if (structuredData[i].id == categoryIdOfItem) {

            let subCategories = structuredData[i].subCategories
            for (let j = 0; j < subCategories.length; j++) {
                if (structuredData[i].subCategories[j].id == subCategoryId) {
                    let list = structuredData[i].subCategories[j].items
                    list.push(item)
                }

            }
        }
    }

}

export function setItemsInStructuredData(categories, items) {
    let structuredData = formatData(categories);

    items.forEach(item => {
        const listOfCategories = item.category;

        listOfCategories.forEach(category => {
            const categoryIdOfItem = category.id
            const listOfSubCategories = category.subCategory;

            listOfSubCategories.forEach(subCategory => {
                fun(item, structuredData, categoryIdOfItem, subCategory)
            });
        });
    });

    return structuredData;
}
