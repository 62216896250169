import React, { useEffect, useState } from 'react';
import Header from '../Components/Header.jsx';
import Footer from '../Components/Footer.jsx';
import { Backend } from '../backend.js';

function Contacts() {
    const [contact, setContact] = useState({ location: '', info: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchContactInfo = async () => {
            try {
                const backend = new Backend();
                const contactData = await backend.getContacts();
                setContact(contactData);
                setLoading(false);
            } catch (err) {
                console.error('Failed to fetch contact information:', err);
                setError('Failed to load contact information');
                setLoading(false);
            }
        };

        fetchContactInfo();
    }, []);

    return (
        <div className="App flex flex-col min-h-screen w-full mt-16">
            <Header />
    
            <div className="flex-grow">
                <h2 className="text-3xl sm:text-5xl font-bold mt-10 sm:mt-32 mx-4 sm:mx-20 mb-5">Контакты</h2>
    
                <div className="flex flex-wrap justify-center pb-5 pt-5 items-start gap-4 w-full">
                    <div className="w-full mx-4 sm:mx-20">
                        {loading ? (
                            <p>Loading...</p>
                        ) : error ? (
                            <p>{error}</p>
                        ) : (
                            <>
                                <p className="text-xl sm:text-3xl mt-3">{contact.location}</p>
                                {contact.info.map((info, index) => (
                                    <div key={index} className="mt-5">
                                        <p className="text-lg sm:text-2xl">{info.phone}</p>
                                        <p className="text-lg sm:text-2xl">{info.email}</p>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </div>
            </div>
    
            <div className="mt-64 sm:mb-0"> {/* Add bottom margin for mobile */}
                <Footer />
            </div>
        </div>
    );
    
    
    
}

export default Contacts;
