import React, { useEffect, useState } from 'react';
// Components
import Header from '../Components/Header.jsx';
import Hero from '../Components/Hero.jsx';
import Card from '../Components/Card.jsx';
import Footer from '../Components/Footer.jsx';

// Data
import { Backend } from '../backend.js';
// import { DynamicIcon } from '../Components/IconTest.jsx';

function Home() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const backend = new Backend();
      const categoriesData = await backend.getCategories();

      const sortedCategories = categoriesData
        .filter(category => category.categoryOrder !== 0)
        .sort((a, b) => a.categoryOrder - b.categoryOrder);

      setCategories(sortedCategories);

    };

    fetchCategories();
  }, []);

  return (
    <div className="App flex flex-col h-full w-full bg-gray-200 text-center">
      <Header />
      <Hero />
      <h2 className="text-3xl sm:text-3xl w-full p-8 pt-0 pb-0 text-sm font-bold mt-10 mb-5">
        Выберите вашу отрасль
      </h2>
      <div className="flex flex-wrap justify-center pb-5 pt-5 items-center gap-4 w-full">
        {categories.map((card, index) => (
          <Card
            key={index}
            title={card.name}
            link={card.id}
            icon={card.googleIcon}
          />
        ))}
      </div>

      {/* <DynamicIcon /> */}

      <Footer />

      <a href='/login' className="material-symbols-outlined fixed bottom-0 left-0 text-xs p-2">settings</a>
    </div>
  );
}

export default Home;
