// src/services/emailService.js

import emailjs from 'emailjs-com';

export const sendFeedbackEmail = async (formData) => {
    const { name, phone, email, message } = formData;

    const templateParams = {
        name,
        phone,
        email,
        message
    };

    try {
        const result = await emailjs.send(
            'service_v1g3k6n',    // Replace with your EmailJS service ID
            'template_v0don7f',   // Replace with your EmailJS template ID
            templateParams,
            '-36ck9OwckXCNdogc'   // Replace with your EmailJS user ID
        );

        if (result.status === 200) {
            return { success: true, message: 'Сообщение отправлено успешно!' };
        } else {
            return { success: false, message: 'Не удалось отправить сообщение. Попробуйте еще раз.' };
        }
    } catch (error) {
        console.error('Ошибка при отправке:', error);
        return { success: false, message: 'Не удалось отправить сообщение. Попробуйте еще раз.' };
    }
};
