import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../api/authLogin';  // Adjust the import path if necessary

function LoginPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            const token = await login(username, password);

            // Store token (e.g., in local storage or a secure cookie)
            // localStorage.setItem('authToken', token);
            console.log(token);
            if (token) navigate('/admin');
        } catch (err) {
            setError("Неправильное имя пользователя или пароль");
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <div className="flex h-screen items-center justify-center bg-gray-900 px-4">
            <div className="w-full max-w-sm p-6 border border-gray-700 rounded-lg shadow-lg text-white bg-gray-800">
                <h2 className="text-2xl mb-6 text-center font-semibold">Вход для администратора</h2>
                {error && <p className="text-red-500 mb-4 ml-4 text-sm -mt-4">{error}</p>}
                <div className="mb-4">
                    <label className="block mb-2">Имя пользователя</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        onKeyDown={handleKeyDown}
                        className="w-full p-3 border border-gray-600 rounded-lg text-gray-900"
                    />
                </div>
                <div className="mb-6">
                    <label className="block mb-2">Пароль</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={handleKeyDown}
                        className="w-full p-3 border border-gray-600 rounded-lg text-gray-900"
                    />
                </div>
                <button
                    onClick={handleLogin}
                    className="w-full bg-gray-700 hover:bg-gray-600 text-white p-3 rounded-lg transition-colors"
                >
                    Войти
                </button>
            </div>
        </div>
    );
}

export default LoginPage;
