import { config } from '../config';

export async function removeItem(id) {
    try {
        const token = localStorage.getItem('authToken'); 

        const response = await fetch(`${config.apiUrl}/api/zmc/removeItem`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({data: id, token: token})
        });

        if (response.ok) {
            const result = await response.json();
            return result.message;  // Assuming the backend returns a message
        } else {
            console.error('Failed to set item');
        }
    } catch (error) {
        console.error('Error:', error);
    }
}
