import React, { useState } from 'react';

const Sidebar = ({ activeMenu, onMenuClick }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <>
      {/* Button to toggle sidebar visibility on mobile */}
      <button
        onClick={handleToggleSidebar}
        className={`fixed top-4 left-4 z-50 text-white bg-gray-800 p-2 rounded-xl shadow-lg md:hidden text-xs ${isSidebarOpen ? 'hidden' : ''}`}
        aria-label="Open sidebar"
      >
        <span className="material-symbols-outlined">menu</span>
      </button>

      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 h-full bg-gray-800 shadow-lg border-r border-gray-700 transition-transform transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } md:translate-x-0 md:relative md:w-1/4 w-64`}
      >
        {/* Title */}
        <div className="h-16 flex items-center justify-center bg-gray-900 text-white">
          <a href="/" className="material-symbols-outlined border-r pr-4 mr-4 text-2xs">logout</a>
          <h1 className="text-xl md:text-2xl font-semibold">Администратор</h1>
        </div>

        {/* Menu Section */}
        <div className="px-4 py-3 bg-gray-800 text-white border-gray-600">
          <h2 className="text-lg md:text-xl font-medium">Меню:</h2>
        </div>

        <nav className="flex-1">
          <ul className="space-y-1 ml-4 md:ml-8">
            <li>
              <button
                onClick={() => onMenuClick('products')}
                className={`w-full text-left py-3 px-4 rounded-tl-lg rounded-bl-lg transition-colors ${
                  activeMenu === 'products' ? 'bg-gray-600 text-white' : 'bg-gray-800 text-gray-300 hover:bg-gray-600 hover:text-white'
                }`}
              >
                Продукты
              </button>
            </li>
            <li>
              <button
                onClick={() => onMenuClick('categories')}
                className={`w-full text-left py-3 px-4 rounded-tl-lg rounded-bl-lg transition-colors ${
                  activeMenu === 'categories' ? 'bg-gray-600 text-white' : 'bg-gray-800 text-gray-300 hover:bg-gray-600 hover:text-white'
                }`}
              >
                Категории
              </button>
            </li>
            <li>
              <button
                onClick={() => onMenuClick('contacts')}
                className={`w-full text-left py-3 px-4 rounded-tl-lg rounded-bl-lg transition-colors ${
                  activeMenu === 'contacts' ? 'bg-gray-600 text-white' : 'bg-gray-800 text-gray-300 hover:bg-gray-600 hover:text-white'
                }`}
              >
                Контакты
              </button>
            </li>
          </ul>
        </nav>

        <div className="px-4 py-3 bg-gray-800 text-white border-gray-600">
          <h2 className="text-lg md:text-xl font-medium">Дополнительные:</h2>
        </div>

        <nav className="flex-1">
          <ul className="space-y-1 ml-4 md:ml-8">
            <li>
              <button
                onClick={() => onMenuClick('showcase')}
                className={`w-full text-left py-3 px-4 rounded-tl-lg rounded-bl-lg transition-colors ${
                  activeMenu === 'showcase' ? 'bg-gray-600 text-white' : 'bg-gray-800 text-gray-300 hover:bg-gray-600 hover:text-white'
                }`}
              >
                Обзор предметов
              </button>
            </li>
            <li>
              <button
                onClick={() => onMenuClick('help')}
                className={`w-full text-left py-3 px-4 rounded-tl-lg rounded-bl-lg transition-colors ${
                  activeMenu === 'help' ? 'bg-gray-600 text-white' : 'bg-gray-800 text-gray-300 hover:bg-gray-600 hover:text-white'
                }`}
              >
                Помощь
              </button>
            </li>
          </ul>
        </nav>



        {/* Close Button */}
        <button
          onClick={handleCloseSidebar}
          className={`absolute bottom-4 left-4 text-white bg-gray-700 p-2 rounded-full w-10 h-10 flex items-center justify-center md:hidden ${isSidebarOpen ? 'block' : 'hidden'}`}
          aria-label="Close sidebar"
        >
          <span className="material-symbols-outlined">close</span>
        </button>
      </div>
    </>
  );
};

export default Sidebar;
