import React, { useEffect, useState } from 'react';
import { Backend } from '../backend';

function Breadcrumbs({ link }) {
    const [breadcrumbs, setBreadcrumbs] = useState({
        link1Name: '',
        link1: '',
        link2Name: '',
        link2: '',
        link3Name: '',
        link3: ''
    });

    useEffect(() => {
        async function fetchData() {
            let backend = new Backend();
            const items = await backend.getItems();
            const categories = await backend.getCategories();

            // Decode the link to handle special characters
            let unformattedLink = decodeURIComponent(link);
            // console.log("Decoded link:", unformattedLink);

            // Split the link by '/' and filter out empty elements
            const list = unformattedLink.split('/').filter(item => item !== '');
            // console.log("Parsed list:", list);

            // Base path for categories
            const base = "/categories";

            // Ensure the link contains enough elements and starts with 'categories'
            if (list.length >= 2 && list[0] === 'categories') {
                // Convert categoryId and subCategoryId to numbers if your backend returns numeric IDs
                const categoryId = Number(list[1]);
                const subCategoryId = list.length >= 3 ? Number(list[2]) : null;
                const itemId = list.length >= 4 ? Number(list[3]) : null;

                // Find the current category by ID
                const currentCategory = categories.find(category => Number(category.id) === categoryId);
                
                if (currentCategory) {
                    // Set breadcrumb for category
                    setBreadcrumbs(prevState => ({
                        ...prevState,
                        link1Name: currentCategory.name,
                        link1: `${base}/${currentCategory.id}`
                    }));

                    // If there's a subcategory, find and set it
                    if (subCategoryId) {
                        const subCategory = currentCategory.subCategories.find(subCat => Number(subCat.id) === subCategoryId);

                        if (subCategory) {
                            // Set breadcrumb for subcategory
                            setBreadcrumbs(prevState => ({
                                ...prevState,
                                link2Name: subCategory.name,
                                link2: `${base}/${currentCategory.id}/${subCategory.id}`
                            }));

                            // If there's an item, find and set it
                            if (itemId) {
                                const item = items.find(item => Number(item.id) === itemId);
                                
                                if (item) {
                                    // Set breadcrumb for item
                                    setBreadcrumbs(prevState => ({
                                        ...prevState,
                                        link3Name: item.title,
                                        link3: `${base}/${currentCategory.id}/${subCategory.id}/${item.id}`
                                    }));
                                }
                            }
                        }
                    }
                }
            }
        }

        fetchData();
    }, [link]);

    return (
        <div className="breadcrumbs text-sm w-5/6">
            <ul>
                <li><a href="/categories">Категории</a></li>
                {breadcrumbs.link1 && <li><a href={breadcrumbs.link1}>{breadcrumbs.link1Name}</a></li>}
                {breadcrumbs.link2 && <li><a href={breadcrumbs.link2}>{breadcrumbs.link2Name}</a></li>}
                {breadcrumbs.link3 && <li><a href={breadcrumbs.link3} dangerouslySetInnerHTML={{ __html: breadcrumbs.link3Name }}></a></li>}
            </ul>
        </div>
    );
}

export default Breadcrumbs;
