import React, { useEffect, useState } from 'react';
import Header from '../Components/Header.jsx';
import Footer from '../Components/Footer.jsx';
import Card from '../Components/Card.jsx';
import { Backend } from '../backend.js';

function Catalog() {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const backend = new Backend();
                const fetchedCategories = await backend.getCategories();

                const sortedCategories = fetchedCategories
                    .filter(category => category.categoryOrder !== 0)
                    .sort((a, b) => a.categoryOrder - b.categoryOrder);


                setCategories(sortedCategories);
                setLoading(false);
            } catch (err) {
                console.error('Failed to fetch categories:', err);
                setError('Failed to load categories');
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    return (
        <div className="App flex flex-col justify-between min-h-screen w-full mt-16">
            <Header />

            <div className='sm:w-11/12 pt-4 sm:ml-12 w-full pl-8'>
                <h2 className="text-3xl sm:text-5xl font-bold sm:mt-8 sm:mb-5">Отраслевые решения</h2>

                <div className="flex flex-wrap justify-left pb-5 pt-5 items-center gap-4 sm:w-full w-full">
                    {loading ? (
                        <p>Loading...</p>
                    ) : error ? (
                        <p>{error}</p>
                    ) : categories.length > 0 ? (
                        categories.map((card, index) => (
                            <Card
                                key={index}
                                title={card.name}
                                link={card.id}
                                icon={card.googleIcon}
                            />
                        ))
                    ) : (
                        <p>No categories available</p>
                    )}
                </div>
            </div>

            <Footer />
        </div>
    );

}

export default Catalog;
