import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../Components/Header.jsx';
import Footer from '../Components/Footer.jsx';
import Breadcrumbs from '../Components/Breadcrumbs.jsx';
import useScrollToTop from '../Components/useScrollToTop.js';
import UsageTable from '../Components/Table.jsx'; // Import the table component
import AdvancedInfoSection from '../Components/InfoSection.jsx'; // Import the new component
import ContactButton from '../Components/ContactButton.jsx'; // Import the new component
import QrCode from '../Components/QrCode.jsx'; // Import the new component

function Item(props) {
    useScrollToTop();
    const navigate = useNavigate();
    const location = useLocation(); // Get the current location

    const topCategory = props.sub || undefined;
    const currentOptions = props.option || undefined;
    const self = props.self || {};
    // console.log(self)
    const title = (self.title || '').charAt(0).toUpperCase() + (self.title || '').slice(1);
    const {
        description = '',
        purpose = '',
        advancedDescription = '',
        technicalCharacteristics = '',
        characteristics = '',
        advantages = '',
        recommendationsForUse = '',
        combinations = '',
        safety = '',
        table = []
    } = self.info || {};

    // Check if the URL contains the word "categories"
    const pathSegments = location.pathname.split('/').filter(segment => segment);
    const isCategoriesPage = pathSegments[0] === 'categories';
    // Create breadcrumb list based on the path segments

    console.log(props)

    return (
        <div className="App flex flex-col min-h-screen w-full mt-16">
            <Header />
    
            <div className="flex-grow flex w-full pl-4 pr-4">
                <div className='pt-4 sm:ml-12 w-full flex-start'>
                    {isCategoriesPage ? (
                        <Breadcrumbs link={location.pathname} />
                    ) : (
                        <button
                            className='rounded-1xl p-1 w-32 border border-black'
                            onClick={() => navigate('/searchpage', { state: {} })}
                        >
                            Вернуться
                        </button>
                    )}
    
                    <div className="container mx-auto mt-4">
                    <h2 className="sm:text-5xl text-3xl font-bold mt-4 mb-5" dangerouslySetInnerHTML={{ __html: title }} />

                    <div className="mb-5 sm:w-5/6 w-full h-1 bg-primary"></div>
                        <div className="mb-5 w-full sm:w-5/6">
                            {description && <AdvancedInfoSection title="Описание" content={description} />}
                            {purpose && <AdvancedInfoSection title="Применение" content={purpose} />}
                            {advancedDescription && <AdvancedInfoSection title="Основные характеристики" content={advancedDescription} />}
                            {technicalCharacteristics && <AdvancedInfoSection title="Технические характеристики" content={technicalCharacteristics} />}
                            {advantages && <AdvancedInfoSection title="Преимущества" content={advantages} />}
                            {recommendationsForUse && <AdvancedInfoSection title="Рекомендации по применению" content={recommendationsForUse} />}
                            {characteristics && <AdvancedInfoSection title="Рекомендации по применению" content={characteristics} />}

                            {table.length > 0 && <UsageTable tableData={table} />}
                            {combinations && <AdvancedInfoSection title="Совместимость" content={combinations} />}
                            {safety && <AdvancedInfoSection title="Безопасность и хранение" content={safety} />}
                        </div>
                    </div>
                </div>
            </div>
    
            <div className='fixed bottom-0 right-0 mr-8 mb-8'>
                <QrCode />
            </div>
    
            <Footer />
        </div>
    );
    
}

export default Item;
