import React from 'react';
import { useNavigate } from "react-router-dom";

function Card({ title, link, icon }) {
    const navigate = useNavigate();

    function goto (tag) {
        navigate('/categories/' + link);
    }

  return (
    <button
      onClick={() => goto(title.toLowerCase())}
      className="card bg-base-100 border-2 border-base-300 sm:w-60 sm:h-60 w-40 h-40 justify-center flex shadow-2xl hover:shadow-3xl hover:border-black transform transition-transform duration-300 hover:scale-105 block rounded-lg"
      style={{ textDecoration: 'none' }}
    >
      <figure className="flex justify-center items-center p-4 w-full text-center">
        <span className={`material-symbols-outlined sm:text-5xl text-4xl text-black-500`}>
          {icon || 'info'}
        </span>
      </figure>
      <div className="card-body text-center p-4 flex-none justify-center flex w-full">
        <h2 className="sm:text-xl text-sm font-bold">{title}</h2>
      </div>
    </button>
  );
}

export default Card;
