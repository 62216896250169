import React from 'react';
import ItemShowcase from '../Components/ItemShowcase.jsx';

function SearchPageRight({ displayItems = [], loading, error, selectedTags }) {
    // Display a message when displayItems is empty
    if (displayItems.length === 0) {
        return (
            <div className="fixed flex items-center justify-center w-2/3 h-screen pb-32">
                <p className="text-3xl font-semibold text-gray-700">
                    Никаких товаров не найдено.
                </p>
            </div>
        );
    }




    // Filter items based on selected tags
    const itemsThatMatchTags = selectedTags.length > 0
        ? displayItems.filter(item => {
            const itemTags = (item.tags || []).map(tag => tag.toLowerCase().trim());
            const normalizedSelectedTags = selectedTags.map(tag => tag.toLowerCase().trim());

            return normalizedSelectedTags.every(tag => itemTags.includes(tag));
        })
        : displayItems;

    // Display the filtered items or a message if no items match
    return (
        <div className="order-2 w-full mt-4 pl-4 pl-1/3 pb-5 pr-4 flex flex-wrap justify-start items-start gap-4 mb-60">
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : itemsThatMatchTags.length > 0 ? (
                itemsThatMatchTags.map((item, index) => (
                    <ItemShowcase
                        key={index}
                        title={item.title}
                        link={`/${item.id}`}
                        description={item.info.description || "У товара нет указанного описания."}
                        purpose={item.info.purpose || "У товара нет указанного применения."}
                        className="w-full md:w-1/2 lg:w-1/3 p-2"
                    />
                ))
            ) : (
                <p>No items found.</p>
            )}
        </div>
    );
}

export default SearchPageRight;
