import React from 'react';

const Help = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl mx-auto mt-10">
      {/* Contact Information */}
      <section className=" p-4 border border-gray-200 rounded-lg bg-gray-50">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Нужна помощь?</h2>
        <p className="text-gray-800 mb-4">
          Если у вас есть вопросы или вам нужна помощь, пожалуйста, свяжитесь со мной:
        </p>
        <p className="text-gray-800 font-semibold">Никита Яроцкий</p>
        <a
          href="mailto:nikeejsg@gmail.com"
          className="text-gray-800 underline"
        >
          nikeejsg@gmail.com
        </a>
      </section>

      <div className="h-2 border border-gray-200 rounded-lg bg-gray-200 mt-2 mb-4 rounded-2xl" ></div>

      {/* Admin Console Information */}
      <section className=" p-4 border border-gray-200 rounded-lg bg-gray-50">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Порадок</h2>
        <p className="text-gray-700 mb-4">
          Ввод '0' в качестве порядка исключит элемент из отображения.
        </p>
      </section>

      <div className="h-2 border border-gray-200 rounded-lg bg-gray-200 mt-2 mb-4 rounded-2xl" ></div>

      {/* Admin Console Information */}
      <section className=" p-4 border border-gray-200 rounded-lg bg-gray-50">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Админ-панель</h2>
        <p className="text-gray-700 mb-4">
          Админ-панель предоставляет инструменты для управления и настройки веб-приложения. Вы можете использовать её для добавления, редактирования и удаления данных, а также для управления различными аспектами приложения.
        </p>
        <p className="text-gray-700 mb-4">
          <strong>Важно:</strong> Всегда сохраняйте все изменения, которые вы вносите в админ-панели. Не сохранённые изменения могут быть потеряны, и это может привести к потере данных или неправильной работе приложения.
        </p>
        <p className="text-gray-700 mb-4">
          Убедитесь, что после внесения любых изменений вы нажали кнопку «Сохранить» и проверили, что ваши изменения были успешно применены.
        </p>
      </section>
    </div>
  );
};

export default Help;
