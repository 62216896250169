import React, { useEffect, useState } from 'react';
import { Backend } from '../backend.js'; 
import { setItemsInStructuredData } from './dataUtils'; 

const backend = new Backend();

const Showcase = () => {
  const [structuredData, setStructuredData] = useState([]);
  const [loading, setLoading] = useState(true); // Added loading state
  const [error, setError] = useState(null); // Added error state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [fetchedItems, fetchedCategories] = await Promise.all([
          backend.getItems(),
          backend.getCategories()
        ]);

        if (!fetchedItems || !fetchedCategories) {
          throw new Error("Fetched data is missing.");
        }

        const completedData = setItemsInStructuredData(fetchedCategories, fetchedItems);
        console.log(completedData)

        setStructuredData(completedData);
        // console.log(structuredData)
      } catch (error) {
        setError(error.message + "sa");
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Ensure loading stops
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <p className="text-center text-xl">Loading...</p>;
  }

  if (error) {
    return <p className="text-center text-xl text-red-500">{error}</p>;
  }

  return (
    <div className="bg-gray-100 min-h-screen py-10 px-5">
      <div className="container mx-auto max-w-6xl">
        <h2 className="text-4xl font-extrabold text-gray-900 mb-6 text-left">
        Просмотр данных
        </h2>

        <div className=" overflow-hidden">
          {structuredData.map(category => (
            <section
              key={category.id}
              className="p-6 bg-gray-900 mb-2 rounded-xl"
            >
              <header className="mb-4">
                <h3 className="text-3xl font-bold text-white ">
                  {category.name}
                </h3>
              </header>

              <div className="">
                {category.subCategories.map(subCategory => (
                  <article
                    key={subCategory.name}
                    className="text-white bg-gray-100 rounded mb-2 pt-4"
                  >
                    <h4 className="text-2xl font-semibold text-black ml-4 mb-4">
                      {subCategory.name}
                    </h4>

                    {subCategory.items.length > 0 ? (
                      <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 pb-4">
                        {subCategory.items.map(item => (
                          <li
                            key={item.linkFormat}
                            className="bg-gray-800 p-4 rounded-lg ml-4 "
                          >
                            <h5 className="text-base font-semibold text-white  truncate" dangerouslySetInnerHTML={{ __html: item.title }}></h5>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="text-black pb-4 text-lg ml-4">Нет доступных элементов.</p>
                    )}
                  </article>
                ))}
              </div>
            </section>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Showcase;
