    // === PAGES ===
import Home from './Pages/Home.jsx';
import Catalog from './Pages/Catalog.jsx';
import SubCatalog from './Pages/SubCatalog.jsx';
import Options from './Pages/Options.jsx';
import Contacts from './Pages/Contacts.jsx';
import Item from './Pages/Item.jsx';
import Admin from './Administrator/Admin.jsx';
import SearchPage from './Pages/SearchPage.jsx';
import Feedback from './Pages/Feedback.jsx';
import LoginPage from './Administrator/LoginPage.jsx';

// === DATA ===
import { Backend } from './backend.js';

const backend = new Backend()
const categories = await backend.getCategories()
const items = await backend.getItems()

if (items && categories) console.log("Data fetched successfully from backend");
else console.log("Error fetching data from backend");

// === ROUTER ===
const routes = [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/categories",
      element: <Catalog />,
    },
    {
        path: "/contacts",
        element: <Contacts />,
    },
    {
        path: "/admin",
        element: <Admin />,
    },
    {
        path: "/searchpage",
        element: <SearchPage />,
    },
    {
        path: "/connect",
        element: <Feedback />,
    },
    {
        path: "/login",
        element: <LoginPage />,
    }
];


categories.forEach((category) => {
    routes.push({
        path: `/categories/${category.id}`,
        element: <SubCatalog name={category} />,
    });

    category.subCategories.forEach((subCategory) => {
        routes.push({
            path: `/categories/${category.id}/${subCategory.id}`,
            element: <Options sub={category} option={subCategory}/>,
        });
    });
});


items.forEach((item) => {
    item.category.forEach((category) => {
        // console.log(category)
        let currentCategory = category.id
        category.subCategory.forEach((subCategories) => {
            // console.log(subCategories)
            // let currentSubCategory = subCategories.toLowerCase().replace(/ /g, '-')

            routes.push({
                path: `/categories/${currentCategory}/${subCategories}/${item.id}`,
                element: <Item self={item}/>,
            });

        })
    })
})

items.forEach((item) => {
    routes.push({
        // path: `/${item.title.toLowerCase().replace(/ /g, '-')}`,
        path: `/${item.id}`,
        element: <Item self={item}/>,
    });
})

let logRoutes = []
routes.forEach((r) => {
    if (r.path != "/admin" && r.path != "/login") logRoutes += r.path + "\n"
})
console.log(`Routes: \n${logRoutes}`)

export default routes;