import axios from 'axios';
import { config } from '../config';

export const login = async (username, password) => {
  try {
    const response = await axios.post(`${config.apiUrl}/api/zmc/login`, { username, password });

    // Access the token from response.data
    const { token } = response.data.response || {};

    if (token) {
      // Store the token in local storage
      localStorage.setItem('authToken', token);
      return true;
    } else {
      throw new Error('Token not found in response');
    }
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
};
