import React from 'react';

function ItemShowcase({ title, link, description, purpose }) {
    const handleNavigation = () => {
        window.location.href = link;
    };

    return (
        <div className="p-5 border rounded-lg shadow-md w-full bg-base-300">
            <h2 className="text-2xl font-bold mb-4" dangerouslySetInnerHTML={{ __html: title }}></h2>

            {description && (
                <>
                    <h2 className="text-lg font-semibold">Описание:</h2>
                    <p className="mb-5" dangerouslySetInnerHTML={{ __html: description }}></p>
                </>
            )}

            {purpose && (
                <>
                    <h2 className="text-lg font-semibold">Применение:</h2>
                    <p className="mb-5" dangerouslySetInnerHTML={{ __html: purpose }}></p>
                </>
            )}

            <button
                className="btn bg-primary text-white"
                onClick={handleNavigation}
            >
                Подробнее
            </button>
        </div>
    );
}

export default ItemShowcase;
