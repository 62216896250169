import React, { useEffect, useState } from 'react';
import { Backend } from '../backend.js';
import ItemDetails from './ItemDetails';

const backend = new Backend();

const Products = () => {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [creatingNewItem, setCreatingNewItem] = useState(false);

  useEffect(() => {
    setCreatingNewItem(false)

    const fetchItems = async () => {
      const fetchedItems = await backend.getItems();
      setItems(fetchedItems);
    };
    fetchItems();
  }, []);

  const handleItemClick = (item) => setSelectedItem(item);

  const handleBack = () => setSelectedItem(null);

  const handleSave = async () => {
    console.log('Saving items:', items);

    try {
        // Send the data to the backend using the setItems method
        const response = await backend.setItems(items);

        if (response) {
            console.log('Items saved successfully:', response);
            // Optionally, display a success message or update the UI
        } else {
            console.error('Failed to save items');
        }
    } catch (error) {
        console.error('Error saving items:', error);
    }
};


  const handleAddNewItem = () => {
    setCreatingNewItem(true)
    setSelectedItem ({
      id: Date.now().toString() + Math.random(), // Ensure uniqueness
      title: 'Новый продукт', // New Item
      linkFormat: '',
      category: [],
      subCategory: [],
      tags: [],
      info: {
        description: '',
        purpose: '',
        characteristics: '',
        technicalCharacteristics: '',
        advantages: '',
        recommendationsForUse: '',
        combinations: '',
        safety: '',
        table: [],
      },
    })
    // setItems([...items, newItem]);
  };

  const handleDeleteItem = async (id) => {
    await backend.removeItem(id);
    const updatedItems = await backend.getItems();
    setItems(updatedItems);
  };
  

  if (selectedItem) {
    return <ItemDetails item={selectedItem}  setCreatingNewItem={setCreatingNewItem} creatingNewItem={creatingNewItem} onBack={handleBack} setItems={setItems} />;
  }

  return (
    <div className="p-6 bg-gray-100">
      <h2 className="text-3xl font-bold mb-8 text-left text-gray-800">Продукты</h2>

      <ul className="grid grid-cols-1">
        {items.map((item) => (
          <li
            key={item.id}
            className="p-4 rounded-lg shadow-sm mb-2 bg-white border border-gray-300 hover:border-gray-500 transition-all cursor-pointer flex justify-between items-center"
          >
            <div
              onClick={() => handleItemClick(item)}
              className="flex-1"
            >
              <h3 className="text-xl font-semibold text-gray-800"  dangerouslySetInnerHTML={{ __html: item.title }}></h3>
            </div>

            {/* <p>Id: {item.id}</p> */}

            <button
              className="ml-4 p-2 pl-4 pr-4 rounded-lg bg-gray-800 text-white shadow-sm hover:bg-red-700 transition-colors"
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering the item click
                handleDeleteItem(item.id);
              }}
            >
              Удалить
            </button>
          </li>
        ))}
      </ul>

      <div className="flex justify-start gap-0 mt-2">
        <button
          className="w-1/2 max-w-[200px] p-3 rounded-lg  bg-gray-800  text-white shadow-md hover:bg-green-700 transition-colors"
          onClick={handleAddNewItem}
        >
         Добавить продукт
        </button>

        {/* <button
          className="w-1/2 max-w-[200px] p-3 rounded-lg bg-blue-600 text-white shadow-md hover:bg-blue-700 transition-colors"
          onClick={handleSave}
        >
          Сохранить все продукты
        </button> */}
      </div>
    </div>
  );
};

export default Products;
