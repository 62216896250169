import { config } from '../config';


export async function getContacts() {
    try {
        const response = await fetch(`${config.apiUrl}/api/zmc/getContacts`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        });

        if (response.ok) {
            const result = await response.json();
            return result.response;
        } else {
            console.error('Failed to fetch contacts');
        }
    } catch (error) {
        console.error('Error:', error);
    }
}
