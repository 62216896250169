import { useState } from 'react';
import { useNavigate } from "react-router-dom";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="navbar bg-base-100 fixed top-0 left-0 right-0 z-50 h-16 border-b-2 flex items-center justify-between px-4 md:px-8">
      <div className="navbar-start flex items-center">
        <a className="flex items-center" href="/">
          <img src="/logo.png" className="w-8 mr-2" alt="Logo" />
          <span className="hidden sm:inline text-lg font-bold">Завод Моющих Средств</span>
        </a>
      </div>

      <div className="navbar-center hidden md:flex">
        <ul className="menu menu-horizontal space-x-4">
          <li><a className="text-sm md:text-base" href="/">Главная</a></li>
          <li><a className="text-sm md:text-base" href="/categories">Отрасли</a></li>
          <li><a className="text-sm md:text-base" href="/contacts">Контакты</a></li>
        </ul>
      </div>

      <div className="navbar-end flex items-center">
        <button className="btn btn-ghost btn-circle mr-2 material-symbols-outlined text-2xl font-light" onClick={() => navigate('/searchpage', { state: {} })}>search</button>

        <a href='/connect' className="btn bg-primary text-white hover:bg-secondary hidden md:inline-flex items-center justify-center text-sm md:text-base mr-2">Обратная Связь</a>
        <button className="md:hidden" onClick={toggleMenu}>
          <span className="material-symbols-outlined">menu</span>
        </button>
      </div>

      {isMenuOpen && (
        <div className="navbar-center md:hidden bg-base-100 w-full absolute top-16 left-0 shadow-md">
          <ul className="menu menu-vertical p-4 space-y-2">
            <li><a className="text-sm" href="/" onClick={toggleMenu}>Главная</a></li>
            <li><a className="text-sm" href="/categories" onClick={toggleMenu}>Каталог</a></li>
            <li><a className="text-sm" href="/contacts" onClick={toggleMenu}>Контакты</a></li>
            <li><a className="btn bg-primary text-white hover:bg-secondary text-sm" href="/connect" onClick={toggleMenu}>Обратная Связь</a></li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default Header;
