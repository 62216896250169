import React, { useState, useEffect } from 'react';
import CategoryItem from './CategoryItem';
import { Backend } from '../backend.js';

const backend = new Backend();

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
        try {
            const fetchedCategories = await backend.getCategories();

            const sortedCategories = fetchedCategories
                .filter(cat => cat.categoryOrder != null)
                .sort((a, b) => a.categoryOrder - b.categoryOrder)
                .concat(fetchedCategories.filter(cat => cat.categoryOrder == null));

            setCategories(sortedCategories);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };
    fetchCategories();
}, []);


  const handleAddCategory = () => {
    const newCategory = {
      id: Date.now(),
      name: 'New Category',
      googleIcon: '',
      categoryOrder: categories.length + 1,
      subCategories: [],
    };
    setCategories(prevCategories => [...prevCategories, newCategory]);
  };

  const handleDeleteCategory = (id) => {
    setCategories(prevCategories =>
      prevCategories.filter(category => category.id !== id)
    );
  };

  const handleAddSubCategory = (categoryId, subCategory) => {
    setCategories(prevCategories =>
      prevCategories.map(category =>
        category.id === categoryId
          ? { ...category, subCategories: [...category.subCategories, subCategory] }
          : category
      )
    );
  };

  const handleDeleteSubCategory = (categoryId, subCategoryId) => {
    setCategories(prevCategories =>
      prevCategories.map(category =>
        category.id === categoryId
          ? {
            ...category,
            subCategories: category.subCategories.filter(sub => sub.id !== subCategoryId)
          }
          : category
      )
    );
  };

  const handleEditSubCategory = (categoryId, subCategoryId, newName, subCatOrder) => {
    setCategories(prevCategories =>
      prevCategories.map(category =>
        category.id === categoryId
          ? {
            ...category,
            subCategories: category.subCategories.map(sub =>
              sub.id === subCategoryId ? { ...sub, name: newName, subCatOrder: subCatOrder } : sub
            ),
          }
          : category
      )
    );
  };

  const handleEditCategory = (categoryId, newName, newGoogleIcon, categoryOrder) => {
    setCategories(prevCategories =>
      prevCategories.map(category =>
        category.id === categoryId
          ? { ...category, name: newName, googleIcon: newGoogleIcon, categoryOrder: categoryOrder }
          : category
      )
    );
  };
  

  async function handleSaveAllData() {
    try {
      setStatus({ type: 'info', message: 'Сохранение данных...' });

      console.log(categories)
      const response = await backend.setCategories(categories);
      if (response && response.success) {
        console.log('Data successfully saved:', response);
        setStatus({ type: 'success', message: 'Данные успешно сохранены.' });

        setTimeout(() => setStatus(null), 2000);

      } else {
        console.error('Failed to save data', response);
        setStatus({ type: 'error', message: 'Не удалось сохранить данные.' });

        setTimeout(() => setStatus(null), 2000);
      }
    } catch (error) {
      console.error('Error saving data:', error);
      setStatus({ type: 'error', message: 'Произошла ошибка при сохранении данных.' });

      setTimeout(() => setStatus(null), 2000);
    }
  }


  return (
    <div className="p-6">
      {status && (
        <div className={`w-full p-4 text-white fixed top-0 left-0 transition-opacity duration-300 z-50 ${status.type === 'success' ? 'bg-green-900' : status.type === 'error' ? 'bg-red-900' : 'bg-blue-900'}`}>
          {status.message}
        </div>
      )}

      <h2 className="text-3xl font-bold mb-6">Категории</h2>

      <ul className="mb-6">
        {categories.map(category => (
          <CategoryItem
            key={category.id}
            category={category}
            onDeleteCategory={handleDeleteCategory}
            onAddSubCategory={handleAddSubCategory}
            onEditSubCategory={handleEditSubCategory}
            onDeleteSubCategory={handleDeleteSubCategory}
            onEditCategory={handleEditCategory}
          />
        ))}
      </ul>

      <button
        onClick={handleAddCategory}
        className="mb-4 w-1/3 p-4 text-sm bg-gray-800 text-white rounded-md hover:bg-green-700"
      >
        Добавить новую категорию
      </button>

      <button
        onClick={handleSaveAllData}
        className="mb-4 w-1/3 p-4 text-sm bg-gray-800 ml-4 text-white rounded-md"
      >
        Сохранить все данные
      </button>
    </div>
  );
};

export default Categories;
