import React from 'react';

function UsageTable({ tableData }) {
    if (!tableData || tableData.length === 0) {
        return null;
    }

    return (
        <div className="mb-5">
        <h2 className="text-xl font-semibold mb-4 text-gray-800">Таблица использования:</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full border-separate border-spacing-0 border border-gray-300">
            <thead>
              <tr className="bg-gray-100 text-sm text-gray-700 uppercase tracking-wider">
                <th className="w-3/5 px-6 py-3 border-b border-gray-300 text-left">Область применения</th>
                <th className="px-6 py-3 border-b border-gray-300 text-left">Концентрация</th>
                <th className="px-6 py-3 border-b border-gray-300 text-left">Время экспозиции</th>
                <th className="px-6 py-3 border-b border-gray-300 text-left">Температура</th>
              </tr>
            </thead>
            <tbody className="text-sm text-gray-600">
              {tableData.map((row, index) => (
                <tr
                  key={index}
                  className={`bg-white`}
                >
                  <td className="w-3/5 px-6 py-4 border-b border-gray-300">{row.title}</td>
                  <td className="px-6 py-4 border-b border-gray-300">{row.concentration}</td>
                  <td className="px-6 py-4 border-b border-gray-300">{row.exposition}</td>
                  <td className="px-6 py-4 border-b border-gray-300">{row.temperature}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      

    );
}

export default UsageTable;
