import React, { useState, useEffect } from 'react';
import Header from '../Components/Header.jsx';
import Footer from '../Components/Footer.jsx';
import { sendFeedbackEmail } from '../Components/email.js';  // Import the service

function Feedback() {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: ''
    });

    const [status, setStatus] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        let timer;
        if (status) {
            timer = setTimeout(() => setStatus(null), 3000);
        }
        return () => clearTimeout(timer);
    }, [status]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.name || !formData.phone || !formData.email || !formData.message) {
            setStatus({ type: 'error', message: 'Пожалуйста, заполните все поля.' });
            return;
        }

        setIsSubmitting(true);
        setStatus({ type: 'info', message: 'Отправка сообщения...' });

        const result = await sendFeedbackEmail(formData);  // Use the service

        if (result.success) {
            setFormData({
                name: '',
                phone: '',
                email: '',
                message: ''
            });
        }

        setStatus({
            type: result.success ? 'success' : 'error',
            message: result.message
        });

        setIsSubmitting(false);
    };

    return (
        <div className="App flex flex-col h-full w-full mt-16">
            <Header />
            {status && (
                <div className={`w-full p-4 text-white fixed top-16 left-0 transition-opacity duration-300 z-50 ${status.type === 'success' ? 'bg-green-500' : status.type === 'error' ? 'bg-red-500' : 'bg-blue-500'}`}>
                    {status.message}
                </div>
            )}

            <div className='w-full pl-4 pr-4 sm:mt-12 mt-8 sm:pl-12 flex-start justify-center'>

                <h2 className='text-2xl sm:text-4xl font-bold mb-4'>Обратная связь</h2>
                <form className='w-full sm:w-1/2 mb-32' onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-sm font-bold mb-2" htmlFor="name">Имя</label>
                        <input
                            className="input input-bordered w-full"
                            id="name"
                            type="text"
                            placeholder="Ваше имя"
                            required
                            value={formData.name}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-bold mb-2" htmlFor="phone">Телефон</label>
                        <input
                            className="input input-bordered w-full"
                            id="phone"
                            type="tel"
                            placeholder="Ваш телефон"
                            required
                            value={formData.phone}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-bold mb-2" htmlFor="email">Email</label>
                        <input
                            className="input input-bordered w-full"
                            id="email"
                            type="email"
                            placeholder="Ваш email"
                            required
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-bold mb-1" htmlFor="message">Сообщение</label>
                        <textarea
                            className="input input-bordered w-full h-24 p-2"
                            id="message"
                            placeholder="Ваше сообщение"
                            value={formData.message}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-4 flex flex-col sm:flex-row items-start sm:items-center w-full">
                        <label className="sm:text-sm text-xs font-bold mb-2 flex items-center">
                            <input className="checkbox mr-2" type="checkbox" required />
                            Я согласен с условиями использования персональных данных
                        </label>
                        <button
    type="submit"
    className={`btn btn-primary mt-4 w-full sm:w-1/4 sm:mt-0 sm:ml-auto text-white ${
        isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
    }`}
    disabled={isSubmitting}
>
    Отправить
</button>

                    </div>
                </form>
            </div>

            <Footer />
        </div>
    );
}

export default Feedback;
