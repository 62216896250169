import React, { useEffect, useState } from 'react';
import { Backend } from '../backend.js';

function Footer() {
  const [categories, setCategories] = useState([]);
  const [contact, setContact] = useState({ location: '', info: [] });

  useEffect(() => {
    const fetchData = async () => {
      const backend = new Backend();
      const fetchedCategories = await backend.getCategories();
      const fetchedContact = await backend.getContacts();

      setCategories(fetchedCategories);
      setContact(fetchedContact);
    };

    fetchData();
  }, []);

  return (
    <footer className="bg-base-300 text-base-content mt-10">
      <div className="container mx-auto py-8 px-4">
        <div className="flex flex-wrap justify-between">
          <div className="w-full lg:w-1/4 mb-6 lg:mb-0 text-left align-center flex flex-col">
          <h2 className="font-bold text-xl mb-2 ">Завод Моющих Средств</h2>

            <img src="/logo.png" className='w-32 mt-4 ml-12' alt="" />
          </div>
          <div className="w-full lg:w-1/4 mb-6 lg:mb-0 text-left">
            <h3 className="font-bold mb-2">НАВИГАЦИЯ</h3>
            <ul>
              <li><a href="/" className="link link-hover">Главная</a></li>
              <li><a href="/categories" className="link link-hover">Каталог</a></li>
              <li><a href="/contacts" className="link link-hover">Контакты</a></li>
              <li><a href="/connect" className="link link-hover">Обратная связь</a></li>
            </ul>
          </div>
          <div className="w-full lg:w-1/4 mb-6 lg:mb-0 text-left">
            <h3 className="font-bold mb-2">ОТРАСЛИ</h3>
            <ul>
              {categories.map((category, index) => (
                <li key={index} className="flex items-center space-x-2">
                  <span className="material-symbols-outlined text-base text-gray-500">
                    {category.googleIcon}
                  </span>
                  <a href={`/categories/${category.id}`} className="link link-hover">
                    {category.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full lg:w-1/4 mb-6 lg:mb-0 text-left">
            <h3 className="font-bold mb-2">КОНТАКТЫ</h3>
            <p>{contact.location}</p>
            {contact.info.map((item, index) => (
              <div key={index} className="mb-2 mt-5">
                <p>{item.phone}</p>
                <p>{item.email}</p>
              </div>
            ))}
          </div>
        </div>
        {/* <div className="mt-8 text-center">
          <p>ООО НПП Химтехнологии © 2024 Все права сохранены</p>
          <p>Создание и продвижение сайтов: АИС-WEB</p>
        </div> */}
      </div>
    </footer>
  );
}

export default Footer;
