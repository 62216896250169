import React, { useState, useEffect } from "react";

const CategoryItem = ({
  category,
  onDeleteCategory,
  onAddSubCategory,
  onEditSubCategory,
  onDeleteSubCategory,
  onEditCategory,
}) => {
  const [categoryName, setCategoryName] = useState(category.name || "");
  const [googleIcon, setGoogleIcon] = useState(category.googleIcon || "");
  const [categoryOrder, setCategoryOrder] = useState(category.categoryOrder || "");
  // const [currentSubCategories, setCurrentSubCategories] = useState([]);

  const currentSubCategories = [
    ...category.subCategories.filter(subCat => subCat.subCatOrder != null)
      .sort((a, b) => a.subCatOrder - b.subCatOrder),
    ...category.subCategories.filter(subCat => subCat.subCatOrder == null)
  ];
  
  useEffect(() => {
    setCategoryName(category.name || "");
    setGoogleIcon(category.googleIcon || "");
    setCategoryOrder(category.categoryOrder || "");

    // const categoryOrder = category.subCategories
    // .filter(subCat => subCat.subCatOrder != null)
    // .sort((a, b) => a.subCatOrder - b.subCatOrder)
    // .concat(category.subCategories.filter(subCat => subCat.subCatOrder == null));

  // setCurrentSubCategories(sortedSubCategories);
  }, [category]);

  const handleNameChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleGoogleIconChange = (e) => {
    setGoogleIcon(e.target.value);
  };

  const handleCategoryOrderChange = (e) => {
    setCategoryOrder(e.target.value);
  };

  const handleBlur = () => {
    if (
      categoryName !== category.name ||
      googleIcon !== category.googleIcon ||
      categoryOrder !== category.categoryOrder
    ) {
      onEditCategory(category.id, categoryName, googleIcon, categoryOrder);
    }
  };

  const handleDeleteCategory = () => {
    onDeleteCategory(category.id);
  };

  const handleAddSubCategory = () => {
    const newSubCategory = {
      id: Date.now(),
      name: "",
      subCatOrder: category.subCategories.length + 1,
    };
    onAddSubCategory(category.id, newSubCategory);
  };


  return (
    <li className="p-6 mb-8 border rounded-lg bg-white shadow-md transition-shadow duration-200 ease-in-out">
      <div className="flex justify-start items-center mb-4">
        <div className="flex justify-start w-2/3 items-center">
          <h2 className="text-md text-gray-900">Название:</h2>
          <input
            type="text"
            value={categoryName || ""}
            onChange={handleNameChange}
            onBlur={handleBlur}
            className="w-1/2 ml-4 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
            placeholder="Название категории"
          />
        </div>
        <div className="flex justify-end w-1/3 items-center mb-4">
          <button
            onClick={handleDeleteCategory}
            className="w-1/2 pl-4/6 p-3 text-sm bg-gray-800 text-white rounded-md hover:bg-red-700"
          >
            Удалить категорию
          </button>
        </div>
      </div>

      <div className="flex justify-start items-center mb-4">
        <h2 className="text-md text-gray-900">Икона:</h2>
        <input
          type="text"
          value={googleIcon || ""}
          onChange={handleGoogleIconChange}
          onBlur={handleBlur}
          className="w-1/3 p-2 ml-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
          placeholder="Google Icon URL"
        />
      </div>

      <div className="flex justify-start items-center mb-4">
        <h2 className="text-md text-gray-900">Порадок:</h2>
        <input
          type="text"
          value={categoryOrder || ""}
          onChange={handleCategoryOrderChange}
          onBlur={handleBlur}
          className="w-1/3 p-2 ml-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
          placeholder="Порадок"
        />
      </div>


      {category.subCategories?.length > 0 && (
        <ul className="space-y-3 pl-6 border-l-2 border-gray-100">
          {currentSubCategories.map((subCategory) => (
            <li
              key={subCategory.id}
              className="p-4 mb-3 border rounded-lg bg-white shadow-sm transition-shadow duration-200 ease-in-out"
            >
              <div className="flex justify-between items-center">
                <input
                  type="text"
                  value={subCategory.name || ""}
                  onChange={(e) =>
                    onEditSubCategory(
                      category.id,
                      subCategory.id,
                      e.target.value,
                      subCategory.subCatOrder
                    )
                  }
                  className="w-full p-2 border rounded-md bg-gray-50 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="Subcategory name"
                />
                <input
                  type="text"
                  value={subCategory.subCatOrder ?? ""}
                  onChange={(e) =>
                    onEditSubCategory(
                      category.id,
                      subCategory.id,
                      subCategory.name,
                      e.target.value
                    )
                  }
                  className="w-1/6 ml-2 p-2 border rounded-md bg-gray-50 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="Порадок"
                />
                <div className="flex space-x-2 ml-4">
                  <button
                    onClick={() =>
                      onDeleteSubCategory(category.id, subCategory.id)
                    }
                    className="px-3 py-1.5 text-sm bg-gray-800 text-white rounded-md hover:bg-red-700"
                  >
                    Удалить
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}

      <button
        onClick={handleAddSubCategory}
        className="mt-4 w-full p-4 text-sm text-white rounded-md bg-gray-800"
      >
        Добавить новую подкатегорию
      </button>
    </li>
  );
};

export default CategoryItem;
