// src/components/ItemInfo.jsx
import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const ItemInfo = ({ label, value, onChange, name }) => {
  return (
    <div className="mb-4">
      <label className="block mb-2"><strong>{label}:</strong></label>
      <Editor
        apiKey='jqlkpnv1r5u58nunefglbiqe0ldv73sjhf3za16zgz70mjj2'
        init={{
          height: 200,
          menubar: false,
          plugins: [
            'link', 'lists', 'table', 'wordcount',
          ],
          toolbar: 'bold italic underline strikethrough subscript superscript link | forecolor backcolor | undo redo',
        }}
        value={value}
        onEditorChange={(content) => onChange({ target: { name, value: content } })}
      />
    </div>
  );
};

export default ItemInfo;

