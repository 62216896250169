import axios from 'axios';
import { config } from '../config';

export const checkAuth = async () => {
  const token = localStorage.getItem('authToken'); // Retrieve token from local storage

  try {
    const response = await axios.post(
      `${config.apiUrl}/api/zmc/checkAuth`,
      {}, // No data is being sent, so pass an empty object
      {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in Authorization header
        },
      }
    );

    return response.data.response.success; // Return the correct field
  } catch (error) {
    console.error('Authentication check failed:', error);
    return false;
  }
};
