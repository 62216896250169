import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Products from './Products';
import Categories from './Categories';
import Contacts from './Contact';
import Sidebar from './Sidebar'; // Import the Sidebar component
import { checkAuth } from '../api/authCheck'; // Assume this is a utility to verify token
import Showcase from './Showcase';
import Help from './Help';

function Admin() {
  const [activeMenu, setActiveMenu] = useState('products'); // Default to 'products'
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is authenticated
    const verifyAuth = async () => {
      const authenticated = await checkAuth(); // Replace with your auth check
      if (!authenticated) {
        navigate('/login');
      } else {
        setIsAuthenticated(true);
      }
    };

    verifyAuth();
  }, [navigate]);

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
    document.activeElement.blur(); // Remove focus from any active element
  };

  const renderContent = () => {
    switch (activeMenu) {
      case 'categories':
        return <Categories />;
      case 'contacts':
        return <Contacts />;
    case 'showcase':
        return <Showcase />;
    case 'help':
        return <Help />;
    case 'products':
      default:
        return <Products />;
    }
  };

  if (!isAuthenticated) {
    return null; // Optionally, show a loader or placeholder while checking auth
  }

  return (
    <div className="flex h-screen w-full bg-gray-100">
      {/* Left Column: Sidebar */}
      <Sidebar activeMenu={activeMenu} onMenuClick={handleMenuClick} />

      {/* Right Column: Main Content */}
      <div className="flex-1 sm:mt-0 mt-12 px-4 py-6 overflow-y-auto">
        {renderContent()}
      </div>
    </div>
  );
}

export default Admin;
