import React from 'react';

function AdvancedInfoSection({ title, content }) {
    if (!content) return null;

    return (
        <div className="mb-5">
            <h2 className="text-xl font-bold">{title}:</h2>
            <h2 className="sm:text-xl text-2xl  mt-4 mb-5" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
    );
}

export default AdvancedInfoSection;
 