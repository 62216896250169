import React, { useState, useEffect } from "react";
import UsageTable from "./UsageTable";
import ItemInfo from "./ItemInfo";
import { Backend } from "../backend.js";
import useScrollToTop from "../Components/useScrollToTop";

const backend = new Backend();

const ItemDetails = ({
  item,
  onBack,
  creatingNewItem,
  setCreatingNewItem,
  setItems,
}) => {
  useScrollToTop();

  const [editableItem, setEditableItem] = useState({
    title: item.title || "",
    linkFormat: item.linkFormat || "",
    category: item.category || [],
    tags: item.tags || [],
    info: {
      description: item.info?.description || "",
      purpose: item.info?.purpose || "",
      characteristics: item.info?.characteristics || "",
      technicalCharacteristics: item.info?.technicalCharacteristics || "",
      advantages: item.info?.advantages || "",
      recommendationsForUse: item.info?.recommendationsForUse || "",
      combinations: item.info?.combinations || "",
      safety: item.info?.safety || "",
      table: item.info?.table || [],
    },
  });

  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState({
    typesOfUsage: [],
    typeOfProduct: [],
  });

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const fetchedTags = await backend.getTags();
        setTags(fetchedTags);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    const fetchCategories = async () => {
      try {
        const fetchedCategories = await backend.getCategories();
        setCategories(fetchedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchTags();
    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [mainKey, subKey] = name.split('.');

    setEditableItem((prevItem) => {
      if (subKey) {
        return {
          ...prevItem,
          [mainKey]: {
            ...prevItem[mainKey],
            [subKey]: value,
          },
        };
      }
      return {
        ...prevItem,
        [name]: value,
      };
    });
  };

  const handleTableChange = (index, field, value) => {
    setEditableItem((prevItem) => {
      const updatedTable = [...prevItem.info.table];
      updatedTable[index] = { ...updatedTable[index], [field]: value };
      return {
        ...prevItem,
        info: {
          ...prevItem.info,
          table: updatedTable,
        },
      };
    });
  };

  const handleAddRow = () => {
    setEditableItem((prevItem) => ({
      ...prevItem,
      info: {
        ...prevItem.info,
        table: [
          ...prevItem.info.table,
          { title: "", concentration: "", exposition: "", temperature: "" },
        ],
      },
    }));
  };

  const handleDeleteRow = (index) => {
    setEditableItem((prevItem) => ({
      ...prevItem,
      info: {
        ...prevItem.info,
        table: prevItem.info.table.filter((_, i) => i !== index),
      },
    }));
  };

  const handleTagChange = (tag) => {
    setEditableItem((prevItem) => {
      const updatedTags = prevItem.tags.includes(tag)
        ? prevItem.tags.filter((t) => t !== tag)
        : [...prevItem.tags, tag];
      return {
        ...prevItem,
        tags: updatedTags,
      };
    });
  };

  const handleCategoryChange = (categoryId, subCategoryId) => {
    setEditableItem((prevItem) => {
      const existingCategory = prevItem.category.find(
        (cat) => cat.id === categoryId
      );

      if (existingCategory) {
        const updatedCategories = prevItem.category.map((cat) =>
          cat.id === categoryId
            ? {
              ...cat,
              subCategory: cat.subCategory.includes(subCategoryId)
                ? cat.subCategory.filter((subCat) => subCat !== subCategoryId)
                : [...cat.subCategory, subCategoryId],
            }
            : cat
        );

        return {
          ...prevItem,
          category: updatedCategories,
        };
      } else {
        return {
          ...prevItem,
          category: [
            ...prevItem.category,
            {
              id: categoryId,
              subCategory: [subCategoryId],
            },
          ],
        };
      }
    });
  };

  const handleSave = async () => {
    const newItem = {
      id: item.id,
      ...editableItem,
      info: {
        ...editableItem.info,
        table: editableItem.info.table.map((row) => ({
          title: row.title,
          concentration: row.concentration,
          exposition: row.exposition,
          temperature: row.temperature,
        })),
      },
    };

    if (creatingNewItem) {
      try {
        const response = await backend.createNewItem(newItem);
        if (response) {
          console.log("Item saved successfully");
        } else {
          console.error("Failed to save item");
        }
      } catch (error) {
        console.error("Error saving item:", error);
      }
    } else {
      try {
        const response = await backend.updateItem(newItem);
        if (response) {
          console.log("Item saved successfully");
        } else {
          console.error("Failed to save item");
        }
      } catch (error) {
        console.error("Error saving item:", error);
      }
    }

    setItems(await backend.getItems());
    setCreatingNewItem(false);
    onBack();
  };

  return (
    <div>
      <button
        onClick={() => {
          setCreatingNewItem(false);
          onBack();
        }}
        className="mb-4 px-4 py-2 bg-gray-800 text-white rounded"
      >
        Назад к списку
      </button>
      <div className="flex flex-row items-center mb-6 mt-2">
        <h2 className="text-3xl font-bold mb-0 mr-2">Редактировать:</h2>
        <p className="mb-0 text-3xl mt-1" dangerouslySetInnerHTML={{ __html: editableItem.title }}></p>
      </div>


      <ItemInfo
        label="Название"
        name="title"
        value={editableItem.title}
        onChange={handleChange}
      />
      <div className="mt-4">
        <h3 className="text-2xl font-semibold mb-2">Категории</h3>
        <div className="ml-1">
          {categories.map((category) => (
            <div key={category.linkFormat} className="mb-4">
              <label className="block mb-2 flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={editableItem.category.some(
                    (cat) => cat.id === category.id
                  )}
                  onChange={() => {
                    const isSelected = editableItem.category.some(
                      (cat) => cat.id === category.id
                    );
                    if (isSelected) {
                      setEditableItem((prevItem) => ({
                        ...prevItem,
                        category: prevItem.category.filter((cat) => cat.id !== category.id),
                      }));
                    } else {
                      setEditableItem((prevItem) => ({
                        ...prevItem,
                        category: [
                          ...prevItem.category,
                          {
                            id: category.id,
                            name: category.name,
                            subCategory: [],
                          },
                        ],
                      }));
                    }
                  }}
                />
                {category.name}
              </label>
              <div className="ml-4">
                {category.subCategories.map((subCat) => (
                  <label
                    key={subCat.linkFormat}
                    className="block mb-1 flex items-center"
                  >
                    <input
                      type="checkbox"
                      className="mr-2"
                      checked={editableItem.category
                        .find((cat) => cat.id === category.id)
                        ?.subCategory.includes(subCat.id) || false}
                      onChange={() =>
                        handleCategoryChange(category.id, subCat.id)
                      }
                    />
                    {subCat.name}
                  </label>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-4">
          <h3 className="text-2xl font-semibold mb-2">Теги</h3>
          <div className="ml-1">
            {Object.keys(tags).map((tagType) => (
              <div key={tagType}>
                {tags[tagType].map((tag) => (
                  <label key={tag} className="block mb-1 flex items-center">
                    <input
                      type="checkbox"
                      className="mr-2"
                      checked={editableItem.tags.includes(tag)}
                      onChange={() => handleTagChange(tag)}
                    />
                    {tag}
                  </label>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <h3 className="text-2xl font-semibold mt-4 mb-4">Информация</h3>
      {[
        {
          id: "info.description",
          label: "Описание",
          name: "info.description",
          value: editableItem.info.description,
        },
        {
          id: "info.purpose",
          label: "Применение",
          name: "info.purpose",
          value: editableItem.info.purpose,
        },
        {
          id: "info.characteristics",
          label: "Характеристики",
          name: "info.characteristics",
          value: editableItem.info.characteristics,
        },
        {
          id: "info.technicalCharacteristics",
          label: "Технические характеристики",
          name: "info.technicalCharacteristics",
          value: editableItem.info.technicalCharacteristics,
        },
        {
          id: "info.advantages",
          label: "Преимущества",
          name: "info.advantages",
          value: editableItem.info.advantages,
        },
        {
          id: "info.recommendationsForUse",
          label: "Рекомендации по использованию",
          name: "info.recommendationsForUse",
          value: editableItem.info.recommendationsForUse,
        },
        {
          id: "info.combinations",
          label: "Совместимость",
          name: "info.combinations",
          value: editableItem.info.combinations,
        },
        {
          id: "info.safety",
          label: "Безопасность",
          name: "info.safety",
          value: editableItem.info.safety,
        },
      ].map((field) => (
        <ItemInfo
          key={field.id}
          label={field.label}
          name={field.name}
          value={field.value}
          onChange={handleChange}
          isMultiline
        />
      ))}

      <UsageTable
        table={editableItem.info.table}
        onTableChange={handleTableChange}
        onAddRow={handleAddRow}
        onDeleteRow={handleDeleteRow}
      />

      <div className="h-2 bg-gray-800 mt-4 mb-2 rounded"></div>

      <button
        onClick={handleSave}
        className="w-full p-4 bg-gray-800 text-white rounded mb-24"
      >
        Сохранить
      </button>
    </div>
  );
};

export default ItemDetails;
