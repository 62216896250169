import React from "react";

const UsageTable = ({ table = [], onTableChange, onAddRow, onDeleteRow }) => {
  return (
    <div>
      <h3 className="text-2xl font-semibold">Таблица использования</h3>

      <table className="w-full border-collapse border border-gray-800 mt-4">
        <thead>
          <tr>
            <th className="border p-2 bg-gray-800 text-white">Название</th>
            <th className="border p-2 bg-gray-800 text-white">Концентрация</th>
            <th className="border p-2 bg-gray-800 text-white">Время воздействия</th>
            <th className="border p-2 bg-gray-800 text-white">Температура</th>
            <th className="border p-2 bg-gray-800 text-white">Действия</th>
          </tr>
        </thead>
        <tbody>
          {table.map((row, index) => (
            <tr key={index}>
              <td className="border border-gray-300 p-2">
                <input
                  type="text"
                  value={row.title}
                  onChange={(e) => onTableChange(index, "title", e.target.value)}
                  className="w-full"
                />
              </td>
              <td className="border border-gray-300 p-2">
                <input
                  type="text"
                  value={row.concentration}
                  onChange={(e) => onTableChange(index, "concentration", e.target.value)}
                  className="w-full"
                />
              </td>
              <td className="border border-gray-300 p-2">
                <input
                  type="text"
                  value={row.exposition}
                  onChange={(e) => onTableChange(index, "exposition", e.target.value)}
                  className="w-full"
                />
              </td>
              <td className="border border-gray-300 p-2">
                <input
                  type="text"
                  value={row.temperature}
                  onChange={(e) => onTableChange(index, "temperature", e.target.value)}
                  className="w-full"
                />
              </td>
              <td className="border border-gray-300 p-2">
                <button onClick={() => onDeleteRow(index)} className="bg-gray-800 hover:bg-red-600 text-white p-4 py-1 w-full rounded">
                  Удалить
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={onAddRow} className="mt-4 px-4 py-2 bg-gray-800 text-white hover:bg-green-700 rounded">
        Добавить строку
      </button>
    </div>
  );
};

export default UsageTable;
