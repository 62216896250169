import React from 'react';

function QRCodeButton() {
    // Get the current page URL
    const pageUrl = window.location.href;
    const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(pageUrl)}`;

    const openQRCode = () => {
        window.open(qrCodeUrl, '_blank');
    };

    return (
        <button onClick={openQRCode} className=" text-white flex items-center justify-center w-10 h-10  ">
            <span className="material-symbols-outlined text-3sm">qr_code</span>
        </button>
    );
}

export default QRCodeButton;
