function Hero() {
    const bgImage = "https://images.pexels.com/photos/1366942/pexels-photo-1366942.jpeg?cs=srgb&dl=pexels-rodolfoclix-1366942.jpg&fm=jpg";

    return (




      <div
        className="hero h-hero shadow-2xl mt-16 bg-base-200" // Adjust height as needed
        style={{
          backgroundImage: `url(${bgImage})`, // Correctly format the background image URL
          backgroundSize: "cover",      // Ensures the image covers the entire hero section
          backgroundPosition: "center", // Centers the image
          backgroundRepeat: "no-repeat" // Prevents the image from repeating
        }}
      >
        <div className="hero-overlay bg-opacity-60"></div>
        <div className="hero-content text-neutral-content text-center">
          <div className="max-w-1/5"> {/* Use Tailwind's predefined max-width classes */}
            <h1 className="mb-5 text-2xl font-bold sm:text-4xl">
            ПРОФЕССИОНАЛЬНЫЕ МОЮЩИЕ И ДЕЗИНФИЦИРУЮЩИЕ СРЕДСТВА          </h1>
            {/* <p className="mb-5 text-5xl">Мы знаем, как помочь вам!</p> */}
            <a href="/searchpage" className="btn bg-primary text-white mr-2 mt-5 border-0">Подобрать средство</a>
            {/* <button className="btn bg-primary text-white ml-2 border-0">Поиск</button> */}
          </div>
        </div>
      </div>
    );
  }

  export default Hero;
