import React, { useState, useEffect } from 'react';
import Header from '../Components/Header.jsx';
import useScrollToTop from '../Components/useScrollToTop.js';
import { useLocation } from 'react-router-dom';
import { Backend } from '../backend.js';
import SearchPageLeft from '../Components/SearchPageLeft.jsx';
import SearchPageRight from '../Components/SearchPageRight.jsx';

function SearchPage() {
    useScrollToTop();

    const [items, setItems] = useState([]);
    const [displayItems, setDisplayItems] = useState([]);  // Add displayItems state
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showSearchComponent, setShowSearchComponent] = useState(false);

    const { state } = useLocation();
    const itemsFromState = state?.items || [];

    useEffect(() => {
        const fetchItems = async () => {
            try {
                if (itemsFromState.length > 0) {
                    setItems(itemsFromState);
                    setDisplayItems(itemsFromState);  // Initialize displayItems
                    setShowSearchComponent(false);
                } else if (items.length === 0) {
                    const backend = new Backend();
                    const fetchedItems = await backend.getItems();
                    setItems(fetchedItems);
                    setDisplayItems(fetchedItems);  // Initialize displayItems
                    setShowSearchComponent(true);
                } else {
                    setShowSearchComponent(true);
                }
            } catch (err) {
                console.error('Failed to fetch items:', err);
                setError('Failed to load items');
            } finally {
                setLoading(false);
            }
        };

        fetchItems();
    }, [items, itemsFromState]);

    const handleSearchButtonClick = () => {
        setShowSearchComponent(false);
    };

    return (
        <div className="App mt-16 min-h-screen flex flex-col">
            <Header />
            <div className="flex flex-col md:flex-row md:justify-between md:items-start h-full w-full overflow-hidden">
                <div className="w-full md:w-1/3 md:relative">
                    <SearchPageLeft
                        setDisplayItems={setDisplayItems}  // Pass setDisplayItems
                        setError={setError}
                    />
                </div>
                <div className="w-full md:w-2/3">
                    <SearchPageRight
                        displayItems={displayItems}  // Pass displayItems
                        loading={loading}
                        error={error}
                        selectedTags={state?.selectedTags || []}
                    />
                </div>
            </div>
        </div>
    );
}

export default SearchPage;
