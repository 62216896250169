import { useLocation, useNavigate } from 'react-router-dom';

// Components
import Header from '../Components/Header.jsx';
import Footer from '../Components/Footer.jsx';
import Breadcrumbs from '../Components/Breadcrumbs.jsx';
import useScrollToTop from '../Components/useScrollToTop.js';

function Catalog(currentCategory) {
    useScrollToTop()

    const location = useLocation(); 

    currentCategory = currentCategory.name
    let currentSubCategories = currentCategory.subCategories
    .filter(subCat => subCat.subCatOrder != null && subCat.subCatOrder !== 0)
    .sort((a, b) => a.subCatOrder - b.subCatOrder)
    .concat(currentCategory.subCategories.filter(subCat => subCat.subCatOrder == null));

    let upperCasedTittle = currentCategory.name

    const navigate = useNavigate();

    function goto (link) {
        navigate("/categories/" + currentCategory.id + "/" + link);
    }

    // console.log(location.pathname)

    return (
        <div className="App flex flex-col min-h-screen mt-16">
          <Header />
          <div className='sm:w-11/12 pt-4 sm:ml-12 w-full pl-8 flex-grow'>
            <Breadcrumbs link={location.pathname} />
            <h2 className="sm:text-5xl text-2xl font-bold mt-4 mb-5">{upperCasedTittle}</h2>
            <div className="flex flex-wrap justify-start items-center mt-8 sm:gap-4 gap-2 w-full mb-? pr-8">
              {currentSubCategories.map((card, index) => (
                <button onClick={() => goto(card.id)} key={index} className="btn btn-primary rounded-6 sm:w-2/6 w-full h-20  font-semibold">
                  <h2 className='text-white sm:text-lg'>{card.name}</h2>
                </button>
              ))}
            </div>
          </div>
          <Footer />
        </div>
      );
      
}

export default Catalog;
